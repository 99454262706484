<template>
	<v-container fluid style="padding:0px;" :class="displaySizeClass">
		<div style="width:100%; height:128px; margin-top:-128px; background-color:gray;" v-if="isSmallScreen!='Mobile'"></div>
        <v-row class="mt-2" no-gutters>
            <v-col cols="12" v-if="!showPaymentButton" class="px-6">
                <v-row>
                    <v-col cols="12" sm="12" md="8" lg="9" xl="9">
                        <v-card>
                            <v-card-title style="justify-content:center;">
                                <h1 style="word-break: break-word;">{{paymentAndShippingInformationLabel[languageId]}}</h1>
                            </v-card-title>
                            <v-card-text class="pb-0">
                                <v-form ref="orderForm">
                                    <h2>{{shippingInfoLabel[languageId]}}</h2>
                                    <v-text-field type="text" :label="nameLabel[languageId]" v-model="form.ShippingName" :rules="nameRules[languageId]"></v-text-field>
                                    <v-text-field type="text" :label="emailLabel[languageId]" v-model="form.ShippingEmail" :rules="emailRules[languageId]"></v-text-field>
                                    <v-text-field type="text" :label="addressLabel[languageId]" v-model="form.ShippingAddress" :rules="addressRules[languageId]"></v-text-field>
                                    <v-text-field type="text" :label="address2Label[languageId]" v-model="form.ShippingAddress2" ></v-text-field>
                                    <v-text-field type="text" :label="zipCodeLabel[languageId]" v-model="form.ShippingZipCode" ></v-text-field>
                                    <v-select type="text" :label="cityLabel[languageId]" v-model="form.ShippingCity" :items="shippingValues" item-text="city" item-value="city" :rules="cityRules[languageId]"></v-select>
                                    <v-select type="text" :label="countryLabel[languageId]" v-model="form.ShippingCountry" :items="countries" item-text="name" item-value="name" :rules="countryRules[languageId]"></v-select>
                                    <v-text-field type="text" :label="phoneLabel[languageId]" v-model="form.ShippingPhone" ></v-text-field>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <h2 class="mr-2">{{paymentInfoLabel[languageId]}}</h2>
                                        </v-col>
                                        <v-col cols="12" sm="6" style="text-align:right;">
                                            <v-btn class="ml-2" text color="secondary" @click="copyShippingInfo()">{{copyShippingInfoLabel[languageId]}}</v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-text-field type="text" :label="nameLabel[languageId]" v-model="form.PayerName" :rules="nameRules[languageId]"></v-text-field>
                                    <v-text-field type="text" :label="emailLabel[languageId]" v-model="form.PayerEmail" :rules="emailRules[languageId]"></v-text-field>
                                    <v-text-field type="text" :label="addressLabel[languageId]" v-model="form.PayerAddress" :rules="addressRules[languageId]"></v-text-field>
                                    <v-select type="text" :label="cityLabel[languageId]" v-model="form.PayerCity" :items="shippingValues" item-text="city" item-value="city" :rules="cityRules[languageId]"></v-select>
                                    <v-select type="text" :label="countryLabel[languageId]" v-model="form.PayerCountry" :items="countries" item-text="name" item-value="name" :rules="countryRules[languageId]"></v-select>
                                </v-form>
                            </v-card-text>
                            <v-card-actions >
                                <v-btn text color="primary" @click="$router.push('/cart')" >
                                    {{returnLabel[languageId]}}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="secondary" @click="sendRequest()" >
                                    {{continueLabel[languageId]}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-row no-gutters>
                            <v-col cols="12" sm="5" md="12" lg="12" xl="12" class="ml-auto mr-auto">
                                <v-card>
                                    <v-card-title>
                                        {{orderSummaryLabel[languageId]}}
                                    </v-card-title>

                                    <v-card-text>
                                        <v-hover v-slot="{hover}">
                                            <v-row no-gutters :style="{backgroundColor: hover?'lightgray':''}">
                                                <v-col cols="4" style="text-align:left;" >{{subtotalLabel[languageId]}}</v-col>
                                                <v-col cols="8" style="text-align:right;" v-if="order.totalValue!=undefined">$ {{(order.totalValue - order.shippingValue).toLocaleString()}}</v-col>
                                            </v-row>
                                        </v-hover>
                                        <v-hover v-slot="{hover}">
                                            <v-row no-gutters :style="{backgroundColor: hover?'lightgray':''}">
                                                <v-col cols="4" style="text-align:left;" >{{shippingLabel[languageId]}}</v-col>
                                                <v-col cols="8" style="text-align:right;" v-if="shippingValue!=undefined">{{shippingValue==0?freeLabel[languageId]:`$ ${shippingValue.toLocaleString()}`}}</v-col>
                                            </v-row>
                                        </v-hover>
                                        <hr>
                                        <v-hover v-slot="{hover}">
                                            <v-row no-gutters :style="{backgroundColor: hover?'lightgray':''}">
                                                <v-col cols="4" style="text-align:left;" >{{totalLabel[languageId]}}</v-col>
                                                <v-col cols="8" style="text-align:right;" v-if="total!=undefined">$ {{total.toLocaleString()}}</v-col>
                                            </v-row>
                                        </v-hover>
                                    </v-card-text>

                                    <v-card-actions>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            
            <v-col cols="12" v-if="showPaymentButton" class="px-6 pb-8">
                <v-card >
                    <v-card-title style="justify-content:center;">
                        <h1 style="word-break: break-word;">{{summaryLabel[languageId]}}</h1>
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card flat>
                                    <v-card-title style="justify-content:center;">
                                        <h3 style="word-break: break-word;">{{orderSummaryLabel[languageId]}}</h3>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-col cols="12" v-for="(item, index) in shoppingCart" :key="index">
                                            <v-row no-gutters style="border-bottom:solid 1px black;">
                                                <v-col cols="4" >
                                                    <v-container fluid class="fill-height"  style="max-height:208px; padding:4px;">
                                                        <v-img :src="require(`../assets/${getImage(item.title)}`)" max-height="200" contain ></v-img>
                                                    </v-container>
                                                </v-col>
                                                <v-col cols="8"   style="text-align:left;">
                                                    <h3 class="mb-2" >{{item.title.split("|")[languageId]}}</h3>
                                                    <v-row no-gutters>
                                                        <v-col cols="4">{{priceLabel[languageId]}}</v-col>
                                                        <v-col cols="8">$ {{(item.unitPrice * item.quantity).toLocaleString()}}</v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4">{{amountLabel[languageId]}}</v-col>
                                                        <v-col cols="8">{{item.quantity}}</v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card flat>
                                    <v-card-title style="justify-content:center;">
                                    </v-card-title>
                                        <h3 style="word-break: break-word;">{{paymentAndShippingInformationLabel[languageId]}}</h3>
                                    <v-card-text>
                                        <h4>{{shippingInfoLabel[languageId]}}</h4>
                                        <v-text-field disabled type="text" :label="nameLabel[languageId]" v-model="form.ShippingName" ></v-text-field>
                                        <v-text-field disabled type="text" :label="emailLabel[languageId]" v-model="form.ShippingEmail"></v-text-field>
                                        <v-text-field disabled type="text" :label="addressLabel[languageId]" v-model="form.ShippingAddress"></v-text-field>
                                        <v-text-field disabled type="text" :label="address2Label[languageId]" v-model="form.ShippingAddress2"></v-text-field>
                                        <v-text-field disabled type="text" :label="zipCodeLabel[languageId]" v-model="form.ShippingZipCode"></v-text-field>
                                        <v-text-field type="text" :label="cityLabel[languageId]" v-model="form.ShippingCity" disabled></v-text-field>
                                        <v-text-field type="text" :label="countryLabel[languageId]" v-model="form.ShippingCountry" disabled></v-text-field>
                                        <v-text-field disabled type="text" :label="phoneLabel[languageId]" v-model="form.ShippingPhone"></v-text-field>
                                        <h4 class="mt-3">{{paymentInfoLabel[languageId]}}</h4>
                                        <v-text-field disabled type="text" :label="nameLabel[languageId]" v-model="form.PayerName"></v-text-field>
                                        <v-text-field disabled type="text" :label="emailLabel[languageId]" v-model="form.PayerEmail"></v-text-field>
                                        <v-text-field disabled type="text" :label="addressLabel[languageId]" v-model="form.PayerAddress"></v-text-field>
                                        <v-text-field type="text" :label="cityLabel[languageId]" v-model="form.PayerCity" disabled></v-text-field>
                                        <v-text-field type="text" :label="countryLabel[languageId]" v-model="form.PayerCountry" disabled></v-text-field>
                                    </v-card-text>
                                </v-card>
                                
                                <v-card flat>
                                    <v-card-title style="justify-content:center;">
                                        <h3 style="word-break: break-word;">{{paymentSummaryLabel[languageId]}}</h3>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row no-gutters >
                                            <v-col cols="4" style="text-align:left;" >{{subtotalLabel[languageId]}}</v-col>
                                            <v-col cols="8" style="text-align:right;" v-if="order.totalValue!=undefined">$ {{(order.totalValue - order.shippingValue).toLocaleString()}}</v-col>
                                        </v-row>
                                        <v-row no-gutters >
                                            <v-col cols="4" style="text-align:left;" >{{shippingLabel[languageId]}}</v-col>
                                            <v-col cols="8" style="text-align:right;" v-if="order.shippingValue!=undefined">{{order.shippingValue==0?freeLabel[languageId]:("$ "+order.shippingValue.toLocaleString())}}</v-col>
                                        </v-row>
                                        <hr>
                                        <v-row no-gutters >
                                            <v-col cols="4" style="text-align:left;" >{{totalLabel[languageId]}}</v-col>
                                            <v-col cols="8" style="text-align:right;" v-if="order.totalValue!=undefined">$ {{order.totalValue.toLocaleString()}}</v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>


                    </v-card-text>
                    
                    <v-card-actions >
                        <v-btn text color="primary" @click="showPaymentButton = false" >
                            {{returnLabel[languageId]}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <form method="post" action="https://checkout.payulatam.com/ppp-web-gateway-payu/">
                            <input name="merchantId"            type="hidden"  :value="merchantId"   >
                            <input name="accountId"             type="hidden"  :value="accountId" >
                            <input name="description"           type="hidden"  :value="paymentDescription[languageId]"  >
                            <input name="referenceCode"         type="hidden"  :value="referenceCode" >
                            <input name="amount"                type="hidden"  :value="amount"   >
                            <!-- <input name="tax"                   type="hidden"  :value="Math.round((amount/1.19)*.19)"  >
                            <input name="taxReturnBase"         type="hidden"  :value="Math.round(amount/1.19)" > -->
                            <input name="tax"                   type="hidden"  :value="0"  >
                            <input name="taxReturnBase"         type="hidden"  :value="0" >
                            <input name="currency"              type="hidden"  :value="form.Currency" >
                            <input name="signature"             type="hidden"  :value="signature"  >
                            <!-- <input name="test"                  type="hidden"  value="1" >
                            <input name="responseUrl"           type="hidden"  :value="pageUrl+'/order-status'" >
                            <input name="confirmationUrl"       type="hidden"  value="https://karlusmorales.com/api/Payment/confirmation" > -->
                            <input name="algorithmSignature"    type="hidden"  value="SHA256" >
                            <input name="buyerFullName"         type="hidden"  :value="form.PayerName" >
                            <input name="buyerEmail"            type="hidden"  :value="form.PayerEmail" >
                            <input name="shippingAddress"       type="hidden"  :value="form.ShippingAddress">
                            <input name="shippingCity"          type="hidden"  :value="form.ShippingCity" >
                            <input name="shippingCountry"       type="hidden"  :value="form.ShippingCountry" >
                            <input name="Submit"                type="submit"  :value="payuButtonLabel[languageId]" class="v-btn v-btn--contained v-btn--is-elevated v-btn--has-bg theme--light v-size--default secondary">
                        </form>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
const baseURL = process.env.VUE_APP_BASE_URL_API;
const captchaKey = process.env.VUE_APP_CAPTCHA_KEY;
import { mapState, mapActions } from "vuex";
	export default {
		name: 'Checkout',
		components: { },
        computed:{
            ...mapState("shoppingCart", 
                [
                    "shoppingCart", "app_token", "order", "artPieces", "languageId", "languages", "returnLabel", "continueLabel", "nameLabel", "emailLabel", "addressLabel", "cityLabel", "countryLabel", "address2Label", "zipCodeLabel", "phoneLabel",
                    "myOrderLabel", "orderSummaryLabel", "shippingLabel", "shippingRegionLabel", "subtotalLabel", "pendingLabel", "verifyLabel", "paymentAndShippingInformationLabel", "shippingValues",
                    "size", "sizePortrait", "printedMedium", "frame", "liner", "images", "collectionNames", "totalLabel", "freeLabel", "summaryLabel", "paymentSummaryLabel", "amountLabel", "payuButtonLabel",
                    "priceLabel","sizeLabel","printedMediumLabel","frameLabel","linerLabel","inStockLabel","outOfStockLabel","sizeErrorMessage","printedMediumErrorMessage","frameErrorMessage","linerErrorMessage","sizeHint","printedMediumHint","frameHint","linerHint","addToCartLabel"
                ]),
            isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
            shippingValue(){
                var quantity = 0;
                for(var i = 0; i < this.form.orderDetails.length; i++){
                    var units = parseInt( this.form.orderDetails[i].quantity );
                    quantity += units;
                }
                return this.shippingValues.find(x=>x.city==this.form.ShippingCity).price * quantity;
            },
            total(){
                return (this.order.totalValue - this.order.shippingValue+this.shippingValue)
            }
        },
		data: () => ({
			pageTitle:['Checkout Karlus Morales','Checkout Karlus Morales'],
			pageMetaTitle:['Checkout Karlus Morales','Checkout Karlus Morales'],
			pageDescription:['Página para realizar el checkout de la compra en Karlus Morales','Checkout page to finish purchase in Karlus Morales'],
            pageKeywords:['Checkout','Checkout'],
            metaImage:'',
            shippingInfoLabel:["Información de envío.","Shipping information."],
            paymentInfoLabel:["Información de pago.","Payment information."],
            paymentDescription:["Compra en karlusmorales.com", "Purchase in karlusmorales.com"],
            copyShippingInfoLabel:["Copiar información","Copy shipping info"],
            form:{
                ShippingName:'',
                ShippingEmail:'',
                ShippingAddress:'',
                ShippingAddress2:'',
                ShippingZipCode:'',
                ShippingCity:'Medellín',
                ShippingState:'',
                ShippingCountry:'Colombia',
                ShippingPhone:'',
                PayerName:'',
                PayerEmail:'',
                PayerAddress:'',
                PayerCity:'Medellín',
                PayerState:'',
                PayerCountry:'Colombia',
                SelectedLanguage:'',
                Currency:'COP',
                totalValue:null,
                taxesValue:null,
                shippingValue:null,
                orderDetails:[],
            },
            nameRules: [
				[
					v => !!v || 'El nombre es necesario.',
					// v => /^[a-zA-Z]+$/.test(v) || 'El nombre solo debe contener letras.',
					v => /^[^!@#$%^&*()\-_=+\\{}[\]<>,.;:"/?~*`|0-9]+?$/.test(v) || 'El nombre solo debe contener letras.',
				],
				[
					v => !!v || 'Name is required.',
					// v => /^[a-zA-Z]+$/.test(v) || 'Name requires only letters.',
					v => /^[^!@#$%^&*()\-_=+\\{}[\]<>,.;:"/?~*`|0-9]+?$/.test(v) || 'Name requires only letters.',
				]
            ],
            emailRules: [
				[
					v => !!v || 'El email es necesario.',
					// v => /.+@.+\..+/.test(v) || 'El email debe tener un formato válido.',
					v => /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'El email debe tener un formato válido.',
				],
				[
					v => !!v || 'Email is required.',
					// v => /.+@.+\..+/.test(v) || 'Email format not valid.',
					v => /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Email format not valid.',
				]
            ],
            addressRules: [
				[
					v => !!v || 'La dirección es necesaria.',
				],
				[
					v => !!v || 'Address is required.',
				]
            ],
            cityRules: [
				[
					v => !!v || 'La ciudad es necesaria.',
				],
				[
					v => !!v || 'City is required.',
				]
            ],
            // stateRules: [
            //     value => !!value || 'Required.',
            // ],
            countryRules: [
				[
					v => !!v || 'El país es necesario.',
				],
				[
					v => !!v || 'Country is required.',
				]
            ],
            sended:false,
            countries:["Colombia"],
            // cities:["Medellín","Bogotá","Cali","Barranquilla"],
            addresses:[],
            
            merchantId:927110,
            accountId:934390,
            amount:0,
            signature:"",
            referenceCode:"",
            showPaymentButton:false,
            // baseUrl:"https://carlos-payutest.azurewebsites.net/", //"https://localhost:44379/"
            pageUrl:"",
        }),
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'noindex, nofollow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
		async mounted () {
            this.form = this.order;
            this.pageUrl = window.location.origin;
            await this.loadRecaptcha(document, "script", "google-recaptcha");
		},
		beforeDestroy(){
			document.getElementsByClassName("grecaptcha-badge")[0].style.display="none";
		},
		methods: { 
            ...mapActions("shoppingCart", ["setMutation", "setOrders"]),
			getImage(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
				var images = this.images.find(x=>x.idArtPiece == artPiece.id)
				return images.base64
			},
            async loadRecaptcha(d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
					d.getElementsByClassName("grecaptcha-badge")[0].style.display="block";
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://www.google.com/recaptcha/api.js?render="+captchaKey;
                fjs.parentNode.insertBefore(js, fjs);
            },
            copyShippingInfo(){
                this.form.PayerName = this.form.ShippingName;
                this.form.PayerEmail = this.form.ShippingEmail;
                this.form.PayerAddress = this.form.ShippingAddress;
                this.form.PayerCity = this.form.ShippingCity;
                this.form.PayerState = this.form.ShippingState;
                this.form.PayerCountry = this.form.ShippingCountry;
            },

            sendRequest(){
                if(!this.$refs.orderForm.validate()) return;
                if(this.sended) return;
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(captchaKey, {action: 'submit'})
                        .then((token) => {
                            this.createOrder(token);
                        });
                })
            },

            createOrder(token){
                var selectedLanguage = this.languages.find(x=>x.id == this.languageId).name;
                this.setOrders({with:selectedLanguage, property:'SelectedLanguage'});
                this.setOrders({with:this.form.ShippingName.trim(), property:'ShippingName'});
                this.setOrders({with:this.form.ShippingEmail, property:'ShippingEmail'});
                this.setOrders({with:this.form.ShippingAddress.trim(), property:'ShippingAddress'});
                this.setOrders({with:this.form.ShippingAddress2.trim(), property:'ShippingAddress2'});
                this.setOrders({with:this.form.ShippingZipCode.trim(), property:'ShippingZipCode'});
                this.setOrders({with:this.form.ShippingCity, property:'ShippingCity'});
                this.setOrders({with:this.form.ShippingState, property:'ShippingState'});
                this.setOrders({with:this.form.ShippingCountry, property:'ShippingCountry'});
                this.setOrders({with:this.form.ShippingPhone.trim(), property:'ShippingPhone'});
                this.setOrders({with:this.form.PayerName.trim(), property:'PayerName'});
                this.setOrders({with:this.form.PayerEmail, property:'PayerEmail'});
                this.setOrders({with:this.form.PayerAddress.trim(), property:'PayerAddress'});
                this.setOrders({with:this.form.PayerCity, property:'PayerCity'});
                this.setOrders({with:this.form.PayerState, property:'PayerState'});
                this.setOrders({with:this.form.PayerCountry, property:'PayerCountry'});
                this.setOrders({with:this.form.SelectedLanguage, property:'SelectedLanguage'});
                this.setOrders({with:this.form.Currency, property:'Currency'});
                this.setOrders({with:this.total, property:'totalValue'});
                this.setOrders({with:this.shippingValue, property:'shippingValue'});
                this.sended = true;
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.app_token}`;
                axios.post(`${baseURL}Orders?captcha=${token}`, this.order)
                    .then(res => {
                        this.sended = false;
                        if(res.status == 200){
                            this.setOrders({with:res.data.orderId, property:'id'});
                            this.amount = this.order.totalValue;
                            this.referenceCode = res.data.response.referenceCode;
                            this.signature = res.data.response.signature;
                            this.showPaymentButton = true;
                        }
                    })
                    .catch(error => { 
                        if(error.response.status==401){
                            this.setMutation({with:null, property:'app_token'});
                        }
                        this.sended = false;
                    });
            },
        },
	}
</script>
